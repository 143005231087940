<template>
    <div class="add-new-holiday">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.customerCreate')"
            :title="$t('messages.customerCreate')"
            action="create"
            @submitPressed="submitPressed"
        />
        <customer-form
            :customerObject="customer"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CustomerForm from "./components/CustomerForm.vue";

export default {
    components: {
        TitleBar,
        CustomerForm,
    },
    data() {
        return {
            action: null,
            customer: {
                role: "ROLE_CUSTOMER",
                address: {},
                customerData: {},
            },
        };
    },
    methods: {
        submitPressed() {
            this.action = "add";
        },
    },
};
</script>
